<template>
    <el-row style="padding-bottom: 20px;">
        <el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
            <Breadcrumb :title="$t('menu.news')" :titleList="[$t('menu.home'), $t('menu.news')]"></Breadcrumb>
            <el-row :gutter="40">
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="(news, index) in newsList" :key="index">
                    <el-image
                        :src="news['coverImage' + $t('common.suffix')] === '' ? '/News/common.png' : '/News/Upload' + news['coverImage' + $t('common.suffix')]"
                        fit="cover" class="news_image" @click="gotoNewsDetails(news)"></el-image>
                    <div class="new_body">
                        <div class="new_title" @click="gotoNewsDetails(news)"
                            v-if="news['title' + $t('common.suffix')] !== ''"> {{ news['title' + $t('common.suffix')] }}
                        </div>
                        <div class="new_title" @click="gotoNewsDetails(news)" v-else> {{ news['title'] }}
                        </div>
                        <div class="new_content" v-if="news['overview' + $t('common.suffix')] !== ''"> {{ news['overview' +
                            $t('common.suffix')] }}</div>
                        <div class="new_content" v-else> {{ news['overview'] }}</div>
                        <div class="new_time"> {{ $t('common.publishedTime') + '：' + formatTime(news['createDate']) }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-pagination small background layout="prev, pager, next, total" :total="total" :page-size="pageSize"
                :v-model:current-page="currentPage" @current-change="handleCurrentChange" />
        </el-col>
        <el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
    </el-row>
    <el-dialog v-model="newsDialogVisible" :fullscreen="fullScreen">
        <div class="new_title_dialog">{{ news['title' + $t('common.suffix')] }}</div>
        <div class="new_time_dialog"> {{ $t('common.publishedTime') + '：' + formatTime(news['createDate']) }}</div>
        <div v-for="(paragraph, index) in news.paragraphs" :key="index">
            <div class="paragraph_text" v-if="paragraph.type === 1">{{ paragraph['value' + $t('common.suffix')] }}</div>
            <div v-else>
                <div class="paragraph_image" v-for="(path, index) in paragraph['value' + $t('common.suffix')].split(';')"
                    :key="index"><el-image v-if="path" :src="'/News/Upload' + path" fit="fill"></el-image></div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import Breadcrumb from "../../components/Breadcrumb.vue";
import { getNewsList, getNews } from '../../api/news.ts'

export default {
    name: "Contact",
    components: {
        Breadcrumb,
    },
    data() {
        return {
            total: 0,
            pageSize: 9,
            currentPage: 1,
            newsList: [],
            news: {},
            newsDialogVisible: false,
            fullScreen: false
        };
    },
    mounted: function () {
        this.getDatas();
    },
    methods: {
        getDatas() {
            getNewsList(this.currentPage, this.pageSize).then(response => {
                if (response.data.code === 20000) {
                    this.total = parseInt(response.data.data.totalCount)
                    this.newsList = response.data.data.pageResult
                }
            })
        },
        gotoNewsDetails(news) {
            getNews(news.id).then(response => {
                if (response.data.code === 20000) {
                    this.fullScreen = document.body.clientWidth < 600
                    this.newsDialogVisible = true
                    this.news = response.data.data
                }
            })
        },
        handleCurrentChange(page) {
            this.currentPage = page;
            this.getDatas();
        },
        formatTime(value) {
            return moment(value).format('YYYY-MM-DD');
        }
    }
};
</script>

<style scoped>
.news_image {
    width: 100%;
    cursor: pointer;
}

.new_body {
    height: 150px;
    padding: 10px;
}

.new_title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.new_time {
    padding-top: 15px;
    font-size: 12px;
    line-height: 1em;
    color: #c0c4cc;
}

.new_content {
    padding-top: 15px;
    font-size: smaller;
    line-height: 1.7em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new_title_dialog {
    font-size: larger;
    text-align: center;
}

.new_time_dialog {
    padding: 15px 0 25px 0;
    text-align: center;
    font-size: 12px;
    line-height: 1em;
    color: #c0c4cc;
}

.paragraph_text {
    text-indent: 2em;
    margin-bottom: 20px;

}

.paragraph_image {
    margin-bottom: 20px;
}
</style>